export const messages = {
    //English Language
    en: {
        login: {
            signIn: 'Log In',
            signInButton: 'Sign In with Google',
            userPass: 'Username and Password',
            logout: 'Logout Session',
            logoutButton: 'Logout',
            send: 'Send',
            back: 'Back',
            username: 'User Name',
            password: 'Password',
            enterUsername: 'Enter your username',
            enterPassword: 'Enter your password',
            loggingin: 'LOGGING IN....'
        },
        admin: {
            title: 'Admin Settings',
            back: 'Back',
            pdfTitle: 'Edit PDF Template',
            users: {
                title: 'Users',
                createUser: 'Create User',
                editUser: 'Edit User',
                placeholderSearch: 'Search User',
                newUser: 'New User',
                fullName: 'Full Name',
                status: 'Status',
                cancel: 'Cancel',
                create: 'Create',
                next: 'Next',
                save: 'Save',
                name: 'Name',
                email: 'Email',
                role: 'Role',
                userCreate: 'User successfully created',
                errorCreateUser: 'Error when creating user',
                errorRole: 'Error when loading roles',
                updateUser: 'Update User with email',
                country: 'Country',
                generic: 'Generic User'
            },
            pharmacy: {
                title: 'Pharmacy',
                cancel: 'Cancel',
                next: 'Next',
                branch: 'Branch',
                branches: 'Branches',
                select: 'Select an option',
                create: 'Create Pharmacy',
                Associated: 'Associated Pharmacies',
                Disassociate: 'Disassociate Pharmacy',
                Back: 'Back',
                search: 'Search Branch',
                message: 'Are you sure you want to disassociate',
                message2: 'of branch',
                save: 'Save',
                editTitle: 'Edit Associated Pharmacy',
                name: 'Name',
                code: 'Code',
                messageError:
                    'No pharmacies were found associated with the headquarters name submitted.',
                update: 'Pharmacy Update',
                updateError: 'An error occurred while update pharmacy',
                deleted: 'Pharmacy Deleted',
                deletedError: 'An error occurred while deleting pharmacy',
                nameInput: 'Pharmacy Name',
                locality: 'Locality',
                branchInput: 'Initial Appointment Branch',
                savePharmacy: 'successfully created',
                errorCreatePharmacy: 'Pharmacy not created',
                errorBranch: 'Error when loading branches'
            },
            auth: {
                title: 'Auth Programs',
                pending: 'Pending Requests',
                approved: 'Requests Approved',
                expired: 'Expired Requests',
                unauthorized: 'Unauthorized Requests',
                date: 'Date',
                profile: 'Profile',
                score: 'Score',
                request: 'Programs to request',
                authorized: 'Authorized by',
                patient: 'Patient',
                amount: 'Amount',
                justification: 'Justification',
                disapproval: 'Justification disapproval',
                notApprove: 'Do not approve',
                approve: 'Approve',
                send: 'Send',
                titleDisapproval: 'Disapproval',
                messageDisapproval: 'Justify here the reason for disapproval',
                placeholder: 'Type your Justification here...',
                notSelected: 'Programs not selected',
                submit: 'Request submitted',
                noData: 'No Requests available',
                name: 'Name',
                dataPatient: 'Patient Data',
                applicant: 'Applicant',
                none: 'None',
                errAuthApprove: 'ERROR: When querying Approved Authorizations',
                errAuthNotApproved: 'ERROR: When querying Authorizations Rejected',
                errAuthPending: 'ERROR: When consulting Pending Authorizations'
            },
            authDiscounts: {
                title: 'Auth Discounts',
                pending: 'Requests discounts',
                approved: 'Requests discounts',
                expired: 'Expired discounts',
                unauthorized: 'Unauthorized discounts',
                show_detail: 'Show Detail',
                product: 'Product',
                precio_lista: 'List Price',
                precio_descuento: 'Discount Price',
                porcentaje_descuento: 'Discount',
                usr_aprueba: 'User Approves',
                usr_solicita: 'User Request',
                fecha_solicita: 'Date request',
                estado: 'Status',
                request: 'Discount to request',
                change_unit: 'change unit of measure',
                usr_desaprobo: 'disapproved user',
                data_pacient: 'Patient data',
                name_pacient: 'Name:',
                data_score: 'Score data',
                need_pacient: 'Need:',
                nhc: 'NHC:',
                score: 'Score:',
                score_mx: 'Score MX:',
                date_first: 'Date first appoiment'
            },
            trm: {
                title: 'TRM Settings',
                value: 'TRM Value',
                edit: 'Edit',
                save: 'Save',
                history: 'History TRM',
                responsible: 'Responsible',
                createAt: 'Create At',
                saving: 'Saving',
                valueUpdate: 'updated TRM Value'
            },
            medicines: {
                title: 'Medicines',
                cycles: 'Cycles',
                stimulation: 'Stimulation',
                dosage: 'Dosage',
                medicine: 'Medicine',
                newOption: 'New Option',
                addedOptions: 'Added Options',
                placeholder: 'Add New Option',
                show: 'Show Details',
                hide: 'Hide details',
                options: 'Options',
                cancel: 'Cancel',
                done: 'Done',
                name: 'Name',
                placeholderSearch: 'Search Grouper',
                edit: 'Edit Grouper',
                create: 'Create Grouper',
                intelisis: 'Intelisis Code',
                validation: 'Each code separated by a comma',
                success: 'Grouper created correctly',
                loading: 'Loading...',
                status: 'Status',
                update: 'Grouper Update correctly',
                msgDuplicate: 'Duplicate intelisis code, please validate information'
            },
            integrator: {
                title: 'Integrator',
                editIntegrator: 'Edit Integrator',
                createIntegrator: 'Create Integrator',
                name: 'Integrator Name',
                company: 'Company',
                success: 'Success',
                messageSuccess: 'Integrator creator correctly',
                messageError: 'Could not create integrator',
                messageSuccessEdit: 'integrator successfully updated',
                messageErrorEdit: 'Could not edit integrator',
                delete: 'Be sure to remove Integrator ?',
                messageDelete: 'Integrator successfully removed',
                messageDeleteError: 'Could not delete integrator'
            },
            top: {
                title: 'Top 10 Cards',
                editCard: 'Edit View',
                createCard: 'Create View',
                nameView: 'View Name',
                optionSede: 'Initial Appointment Branch',
                optionTop: 'Top 10 Options',
                search: 'Search Product',
                initialAppointment: 'Please select an Initial Appointment Branch',
                category: 'Please select a Category',
                pharmacy: 'Please select a Pharmacy',
                rol: 'Please select a Rol',
                selectCategory: 'Please select a category',
                branch: 'Branch',
                name: 'Name',
                errorBranch: 'Api error when querying branches',
                errorRol: 'Api error when querying roles',
                errorProducts: 'Api error when querying products',
                errorCreatingRegeneraProducts: 'Error creating regenera products',
                notSelectInitialAppointment: 'You have not selected an Initial Appointment Branch',
                notSelectCategory: 'You have not selected a Category',
                notSelectRol: 'You have not select a Role',
                repeatFiels: 'There are repeated fields, please validate again',
                greater: 'There are values greater than 10, validate again',
                less: 'There are values less than 1, validate again',
                success: 'Successful',
                createView: 'View created correctly',
                errorCard: 'Error when creating card',
                messageSuccessEdit: 'Information updated',
                errorUpdateCard: 'Error updating ',
                delete: 'Be sure to remove?',
                messageDelete: 'Deleted',
                errorDelete: 'Error while deleting',
                optionTopWarning: 'You must select at least one top option',
                existCard: 'The card to be saved already exists.',
                errorPharmacy: 'Error when listing pharmacies',
                notSelectPharmacy: 'You have not selected a Pharmacy',
                allBranch: 'All Branch'
            },
            role: {
                title: 'Admin Roles',
                titleBusiness: 'Business , Programs and Pdf Administration',
                titleDiscounts: 'Discount Administration',
                subtitle: 'Role Settings',
                name: 'Role Name',
                createMode: 'Create Mode',
                editMode: 'Edit Mode',
                addRole: 'Add a Role +',
                msgRole:
                    'If the Role name contains the word admin it will be an Administrator role.',
                edit: 'Edit',
                delete: 'Delete',
                auth: 'Authorize',
                search: 'Search role',
                msgPermissions:
                    'Unable to display list of Roles by permissions, Contact the Super Administrator',
                createConf: 'Create Configuration',
                clone: 'Clone',
                msgPermissionList:
                    'Unable to display Roles Configuration list by permissions, Contact Super Administrator',
                actions: 'Actions',
                country: 'Country',
                branch: 'Branch',
                formRole: 'Role Configuration Form',
                role: 'Role',
                selectRole: 'Select a Role',
                selectCountry: 'Select a Country',
                selectBranch: 'Select a Branch',
                administrator: 'Administrator',
                accessWindow: 'Access Window',
                programs: 'Programs',
                techniques: 'Techniques',
                labs: 'Labs',
                surgeries: 'Surgeries',
                individualMedicina: 'Individual Medicine',
                packageMedicine: 'Package Medicine',
                history: 'Proposal History',
                sspta: 'Request for Proposal (SSPTA)',
                medicines: 'Medicines',
                cycles: 'Cicles',
                recipe: 'Recipe',
                pdf: 'Pdf Editor',
                showPdf: 'Show PDF',
                imgPDF: 'Image Configuration',
                save: 'Save PDF',
                create: 'Create',
                list: 'List',
                userManagement: 'User Management',
                pharmacy: 'Pharmacy',
                authPrograms: 'Auth Programs',
                authDiscounts: 'Auth Discounts',
                accept: 'Accept Requests',
                reject: 'Reject Requests',
                adminMed: 'Medication Administration',
                listGrouper: 'List Grouper',
                createFilter: 'Create Filter',
                createGrouper: 'Create Grouper',
                editGrouper: 'Edit Grouper',
                change: 'Change Grouper Status',
                integrator: 'Integrator',
                listRole: 'List Role',
                createRole: 'Create Role',
                editRole: 'Edit Role',
                deleteRole: 'Delete Role',
                listConf: 'List Role Configuration',
                createConfRole: 'Create Role Configuration',
                editConf: 'Edit Role Configuration',
                cloneConf: 'Clone Role Configuration',
                deleteConf: 'Delete Role Configuration'
            },
            bussiness: {
                title: 'Admin Business',
                admin: 'Branch Administration',
                subtitle: 'Business and Category Administrator',
                search: 'Search Business',
                searchPrograms: 'Search Programs',
                msgUnable:
                    'Unable to display Branch and Business Configuration List for permissions, Contact Super Administrator',
                formBussiness: 'Business and Category Configuration Form',
                formPrograms: 'Program & PDF Configuration Form',
                showCategory: 'Categories of products to show',
                selectCategory: 'Select a Category',
                Category: 'Categories',
                nameCard: 'Label Card Name',
                unique: 'Unique',
                add: 'Add',
                searchProduct: 'Search Product',
                product: 'Product',
                pipelineCreate: 'Pipeline where you will create Business',
                pipelineStage: 'Stage where the Business will arrive',
                categoryAssociate: 'Category associated with the Business',
                conditionProduct: 'Patient proposal generation restrictions',
                conditionPrograms: 'Conditions for Programs',
                showPrograms: 'Programs to display',
                searchProperty: 'Search Property',
                optionHubspot: 'Hubspot Option',
                pdfCondicion: 'PDF color conditions',
                nameHS: 'General Internal Name in HS',
                nameOptionHS: 'Internal Option Name in HS',
                addProperty: 'Add Additional Property',
                showPDF: 'Then show the PDF',
                optional: 'Optional',
                colorShow: 'Color to display in Pdf',
                optionalColor: 'Optional color to display in pdf',
                name: 'Name',
                code: 'Code',
                bitregenera: 'Regenera',
                estatusregeneraActivo: 'Active',
                estatusregeneraInactivo: 'Inactive',
                estatus: 'Status',
                createdRegeneraProduct: 'Regenera Product created successfully',
                bitregeneraUpdate: 'Bit Regenera configuration correctly updated',
                agregarproductoregenera: 'Add Regenera Product',
                category: 'Category',
                typeCard: 'Type Card',
                actions: 'Actions',
                options: 'Options',
                view: 'View',
                msgDeleteConfirm: 'Are you sure to delete Branch and Business Configuration ?',
                msgConfigRemoved: 'Configuration Successfully removed',
                errorDelete: 'Error deleting Business Pipelines',
                errorListPipelines: 'Error al listar Pipelines (Propiedades de contacto)',
                errorListPipelinesTwo: 'Error al listar Pipelines',
                bussinessUpdate: 'Business updated correctly',
                errorUpdateBussiness: 'Error updating Business Pipelines',
                bussinessCreate: 'Business created correctly',
                errorCreateBussiness: 'Error creating Business Pipelines',
                duplicatePipeline: 'Duplicate Pipeline, a configuration already exists',
                confirmDelete: 'Are you sure to delete category ?',
                msgDeletePipeline: 'Will delete the pipelines associated to this category',
                selectOpcion: 'Select an Option',
                deleteCondition: 'Are you sure to delete condition ?',
                deleteConditionpPdf: 'Are you sure to delete pdf condition ?',
                deleteConditionProgram: 'Are you sure to remove condition from program settings?',
                programsAdmin: 'Admin Programs & Pdf',
                productRegeneraAdmin: 'Regenera Products',
                msgSelectCountry: 'You must select a country to see the products',
                clear: 'Clear',
                programsCreate: 'Program configuration created correctly',
                errorCreateProgram: 'Error creating Program configuration',
                duplicateProgram: 'Duplicate program configuration, validate the information',
                programsUpdate: 'Program configuration correctly updated',
                errorUpdateProgram: 'Error updating program configuration',
                msgDeleteProgram: 'Are you sure to delete the program configuration?',
                configHubspot: 'Hubspot properties configuration',
                nameConfiguration: 'Configuration Name',
                PropertyValue: 'Property Value',
                notBecYsah: 'PROGRAM IN PROGRESS',
                bundle: 'Bundles',
                btnBundle: 'Create Bundle'
            },
            discounts: {
                title: 'Discounts',
                search: 'Search discount',
                name: 'Name',
                country: 'Country',
                discount: 'Discount',
                discountUpdate: 'Discount configuration correctly updated',
                errorDiscountUpdate: 'Error updating discount',
                updateDiscounts: '{number} records have been updated',
                updateDiscountsConfirm: '{number} records to be updated',
                formDiscount: 'Discount Configuration Form',
                errorListContactProps: 'Error when listing Pipelines (Contact Properties)',
                configHubspot: 'Hubspot properties configuration',
                addProperty: 'Add Additional Property',
                clear: 'Clear',
                category: 'Category',
                selectCategory: 'Select a Category',
                product: 'Product',
                selectProduct: 'Select a Product',
                allProducts: 'All Products',
                nameConfiguration: 'Configuration Name',
                vigencyDate: 'Vigency date',
                save: 'Save',
                saveVigency: 'Save vigency',
                selectAll: 'Select all',
                unselectAll: 'Unselect all',
                saveDate: 'Date successfully saved',
                errorSaveDate: 'Error saving date',
                saveDiscounts: 'Save discounts'
            },
            branchMatriz: {
                title: 'Branch & Matrix Configuration',
                notPredefined: 'Branch and Matrix not predefined',
                predefined: 'Predefined Branch - Matrix Configuration',
                branch: 'Branch',
                matrix: 'Matrix',
                selectBranch: 'Select a branch',
                selectMatrix: 'Select a matrix',
                category: 'Category',
                selectCategory: 'Select a Category',
                product: 'Product',
                selectProduct: 'Select a Product',
                exceptions: 'Exceptions',
                distribution: 'Distribution',
                actions: 'Actions',
                deleteConfiguration: 'Delete Configuration',
                questionContinue: 'Do you want to continue?',
                associatedMatrix: 'Associated matrix Non-existent',
                allProducts: 'All Products',
                success: 'Success',
                msgSuccess: 'Configuration successfully created',
                msgUpdate: 'Configuration successfully updated',
                msgDelete: 'Configuration successfully deleted'
            },
            bundles: {
                addphase: 'Add Phase',
                name: 'Name',
                phase: 'Phase',
                optional: 'Optional',
                alert: 'Cannot be added. Product already exists within the phase',
                branches: 'Branches',
                percentages: 'Percentages %',
                select: '-- Please select a product --',
                singleBusiness: 'Is a single Business?',
                chooseOrigins: 'Choose Origins',
                chooseDestinations: 'Choose Destinations',
                addProduct: 'Add Product',
                bundleCreated: 'Bundle created successfully!',
                loading: 'Loading...',
                details: 'Details',
                edit: 'Edit',
                enable: 'Enable',
                disable: 'Disable',
                required: 'All fields in a bundle are required',
                cancel: 'Cancel',
                save: 'Save',
                createBundle: 'Creating bundle...',
                origin: 'Origin',
                destination: 'Destination',
                single: 'Single Business',
                productName: 'Product Name',
                price: 'Price',
                actions: 'Actions',
                hsCreationMessage: 'Business created successfully!',
                noBundle: 'There are no surrogate bundles for the selected country.'
            }
        },
        welcome: {
            welcome: 'Welcome',
            please: 'please find your patient',
            country: 'Select your country',
            email: "Enter patient's email",
            nhc: 'Enter Patient´s ID (NHC)',
            search: 'Search',
            usa: 'USA',
            error: {
                contact: 'Contact',
                owner_id: 'does not have owner_id',
                doctor_pvr: 'Contact ${correo_contacto} does not have a pvr us doctor',
                age: 'Contact ${contact_mail} does not have age',
                name: 'Contact ${contact_email} does not have a first name or last name',
                pvr_status:
                    'Contact ${contact_mail} does not have pvr status or pvr checkout confirmation',
                fertility_treatments:
                    'Contact ${contact_email} does not have value in the how_much_do_you_know_about_fertility_treatments property',
                about_yourself:
                    'Contact ${contact_email} may not have value in any of the properties: how_much_do_you_know_about_fertility_treatments_ or tell_us_more_about_yourself'
            }
        },
        history: {
            Title: 'Proposal History',
            femenino: 'Female',
            masculino: 'Male',
            Gender: 'Gender',
            Patient: 'Patient',
            Proposal: 'New Proposal',
            table: {
                Quote: 'Quote number',
                Date: 'Date',
                Price: 'Price',
                Status: 'Status',
                Details: 'Details',
                entregada: 'Delivered',
                See: 'See',
                detailBusiness: {
                    Quote: 'Quote Number',
                    Detail: 'Detail',
                    Price: 'Price',
                    Created: 'Created',
                    Business: 'Business',
                    Category: 'Category',
                    BusinessCreated: 'Business created',
                    Country: 'Country',
                    Products: 'Products',
                    Articles: 'Articles',
                    Article: 'Article',
                    Code: 'Code'
                }
            },
            pdf: {
                view: 'View PDFs History',
                recent: "Recent Documents PDF's",
                button: 'View PDFs History'
            }
        },
        menu: {
            Admin: 'Admin Settings',
            Form: 'Quotations',
            History: 'Proposal History',
            Proposal: 'Request for proposal',
            Medicine: 'Medicine',
            Cycles: 'Cycles',
            Key_Differentiators: 'Key Differentiators',
            Recipe: 'Request for Proposal Summary',
            Logout: 'Logout',
            onboarding: 'Onboarding Medical'
        },
        form: {
            //admin editor PDF
            Title: 'Quotations - Service Proposal',
            Country: 'Select country',
            Program: 'Select the program',
            Images: 'Images',
            TableProgram: 'Table(Program)',
            TableCoverage: 'Table(Coverage)',
            Position: 'Position',
            FileName: 'File Name',
            View: 'View',
            Actions: 'Actions',
            Save: 'Save',
            Delete: 'Delete',
            Update: 'Update',
            TypeA: 'Road Type A',
            TypeV: 'Road Green',
            TypeB: 'Road Blue',
            variables: 'Editor Variables',
            Language: 'Choose the language',
            uploadImage: 'Upload Image',
            size: 'Image width size maximum 650px',
            view: 'View',
            Yellow: 'Yellow',
            Intermediate: 'Intermediate',
            IntermediateHight: 'Intermediate-High',
            IntermediateLow: 'Intermediate-Low',
            White: 'White',
            Score: 'Choose the score',
            Variables: {
                nhc: "Patient's NHC",
                timestamp: 'Current date',
                patientName: 'Patient Name',
                programName: 'Program Name',
                docInfo: 'Doctor name',
                medAssistance: 'Medical assistant name',
                tableComponents: 'Component Table',
                tableMedicine: 'Medicine Table',
                addressUS: 'Office address USA',
                addressMX: 'Office address Mexico',
                sede: 'Branch selected by user',
                breakPage: 'Break Page'
            },
            Alert: {
                none: 'You have not selected any image',
                repeat: 'Repeated position in Images(Road)',
                repeatText: 'Please validate the position of each image.',
                saveTitle: 'Do you want to save the text and/or images?',
                saveText: 'Will replace the current content of the pdf',
                save: 'Save',
                noSave: "Don't Save",
                cancel: 'Changes not saved',
                okTitle: 'Loading...',
                okText: 'Saving pdf and images',
                error: 'An error occurred while uploading images'
            }
        },
        proposal: {
            Title: 'Proposal request',
            Button: 'No proposal required',
            Programs: 'Programs',
            ProgramsUsa: 'YOUR STAR AT HOME PROGRAMS',
            Protection: 'Protection',
            Techniques: 'TECHNIQUES',
            Coverage: 'Coverage',
            Laboratory: 'LABORATORY',
            Go: 'Go to Request for Proposal Summary',
            Tecnica: 'Tecniques',
            noSelected: 'You have not selected a Pharmacy',
            noFound: 'Must select a pharmacy or pharmacy has no drugs',
            errorIndividual: 'There are no results for Individual Medicine',
            Surgery: 'SURGERIES',
            Eligender: 'Eligender',
            Treatment: 'TREATMENT',
            FirstAppointment: 'FIRST APPOINTMENT',
            Mac: 'PROGRAM MAC',
            noRecords: 'there are no records',
            Price: 'Price',
            Article: 'Article',
            noProposal: {
                title: 'Reasons no proposal required',
                placeholder: 'Enter something... Min 5 characters',
                buttonConfirm: 'Confirm',
                buttonCancel: 'Cancel'
            },
            AdminPermisions: {
                buttonShow: 'Show all programs',
                title: 'Administrator permissions',
                text: 'Choose the programs you want to be authorized',
                justify: 'Justify your request here',
                justifyPlaceholder: 'Write your justification',
                buttonSend: 'Send Request',
                buttonCancel: 'Cancel',
                wait: 'Wait a moment...',
                process:
                    'Processing your request, you can close this popup, the request is made in the background.',
                messageSucess: 'Request Approved',
                approvedPrograms: 'Approved Programs',
                authorized: 'Authorized by',
                messageError: 'Request Not Approved',
                justification: 'Justification Disapproval',
                unauthorized: 'Unauthorized by',
                timeExceeded: 'Time exceeded for this request',
                requestCancelled: 'Request cancelled',
                statusRequest: 'Status Request',
                detailsRequest: 'Details Last Request',
                ProgramsRequest: 'Programs Request',
                AuthorizationStatus: 'Authorization status',
                DateRequest: 'Date of request',
                Applicant: 'Applicant',
                reason: 'Reason not authorized',
                authorizer: 'Authorizer',
                close: 'Close'
            },
            Medicine: {
                titlePackage: 'MEDICATION PACKAGE',
                titleIndividual: 'INDIVIDUAL MEDICINE',
                Pharmacies: 'PHARMACIES',
                search: 'Search Medicine',
                searchIndividual: 'Search Individual Medicine',
                Amount: 'Amount',
                Medicine: 'Medicine',
                Price: 'Price',
                Cash: 'Debit Card Price',
                Cash2: 'Cash Price',
                Credit: 'Credit Price',
                MSI: 'MSI Price'
            }
        },
        medicine: {
            title: 'Medicines',
            medicationManagement: 'Medication Management',
            Fertility: 'Fertility',
            textMedicineUS: 'Patients don´t buy their medicines at Ingenes',
            date: 'Prescription date',
            titlePackage: 'Medication Package',
            titleIndividual: 'Individual Medicine',
            Medicine: 'Medicine',
            Include: 'Include',
            Delivered: 'Delivered',
            Remaining: 'Remaining',
            Detail: 'Detail',
            cycle: 'Cycle Type',
            select: 'Select',
            stimulation: 'Stimulation Type',
            dosage: 'Dosage Type',
            medicine: 'Medicine Type',
            msgStimulation: 'You have not selected a Cycle',
            msgDosage: 'You have not selected a Cycle',
            msgMedicine: 'You have not selected a Dosage',
            apply: 'Apply',
            delete: 'Delete',
            warning: 'Warning',
            incomplete: 'Incomplete fields',
            confirmButton: 'Confirm Medicine',
            textAlert: `<div class="text-left">
          <p>The amount of medicine exceeds the number of medicines remaining,
          Please select one of the following options. </p><br>
          <p> <b>1) Correct:</b> A quantity adjustment is made to the value of the remaining medications. </p>
          <p> <b>2) Create business:</b> the authorization for the remaining quantities is generated and the prescription page opens to confirm the creation of new business for the additional or greater than remaining quantities.</p>
        </div>`,
            correct: 'Correct',
            CreateBusiness: 'Create Business',
            continue: 'Do you want to continue ?',
            table: {
                medicine: 'Medicine',
                includes: 'Includes',
                delivered: 'Delivered',
                remaining: 'Remaining',
                select: 'Select',
                amount: 'Amount'
            },
            success: 'Success',
            msgSuccess: 'Business Created correctly',
            selectMedicine: 'You have not selected any Medicine',
            correctMedicine: 'Cannot be corrected because there are no medicines remaining',
            msg1:
                'Business Created correctly, this view shows the surplus medicines with the special price.',
            msg2: 'This view shows the surplus medicines with the special price.'
        },
        navbarInfo: {
            Quotation: 'Quotation',
            USA: 'USA',
            sedeOrigen: 'Initial Appointment Branch',
            sedeDestino: 'Treatment Branch',
            patientName: 'Name',
            labelsubtipo: 'Subtype',
            labelperfil: 'Profile',
            labeltipo: 'Type'
        },
        recipe: {
            Title: 'Proposal request(SSPTA)',
            smallTitle: 'Request for Proposal Summary',
            Button: 'New Proposal',
            titleMain: 'Request for Proposal Summary',
            components: 'Additional Components',
            componentsMac: 'Mac Requests',
            loadingPdf: 'Loading PDF',
            loadingPdfMed: 'Loading Medicine PDF',
            loadingPdfMac: 'Loading Mac PDF',
            waitMoment: 'Wait a Moment',
            Table: {
                Unit: 'Unity',
                Description: 'Description',
                Quantity: 'Quantity',
                Price: 'Unit Price',
                PriceTotal: 'Total Price'
            },
            Alert: {
                noCreate:
                    'You cannot create a business because the contact does not have an Owner ID',
                createTitle: 'Are you sure you want to create these businesses?',
                createText: "You won't be able to revert this!",
                save: 'Save',
                successTitle: 'Confirmation',
                successText: 'Your businesses have been successfully created in HubSpot',
                errorText: "Your businesses haven't been created in HubSpot",
                cancelTitle: 'Cancelled',
                cancelText: 'Process canceled!',
                Warning: 'Warning',
                processBussiness: 'Patient has business in process.',
                businessControl: 'Business control api error',
                stage: 'Stage',
                bussinessId: 'Bussness ID',
                pipeline: 'Pipeline Name',
                bussiness: 'Bussiness',
                onlyCreate: 'It is only allowed to create',
                continue: 'Do you want to continue?',
                notAllowed: 'You are not allowed to create Technics and YSAH',
                noBec:
                    'You are not allowed to create BAC, this program already exists. Other items will be created',
                noYASH:
                    'You are not allowed to create YSAH, this program already exists. Other items will be created',
                errorCreateProducts: 'Error when generating JSON product data',
                msgLoading: 'Please do not close the page until the process is finished.'
            },
            Confirm: 'Confirm',
            PDF: 'View PDF',
            Loading: 'Loading....',
            PdfMedicine: 'View Medicine PDF'
        },
        permissions: {
            unauthorized: 'You are not authorized to enter',
            validateAdmin: 'Validate with the Administrator',
            configAdmin: 'Administrator settings',
            unauthorizedRol:
                'The user does not have a role configured or does not have permissions.',
            serverError: 'Server Error, try again',
            errorCard: 'Error getting Card Country',
            noAssociatedScore: 'The patient has no associated score to print the pdf',
            notSelectProduct: 'You have not selected any product',
            notSelectBranch: 'You have not selected a Branch',
            errorProducts: 'Error when querying products',
            errorMedicine: 'Error creating medication quote',
            errorQuote: 'Error creating quote',
            errorPdf: 'Error uploading pdf',
            errorRole: 'Error listing Roles',
            errorConfigRole: 'Error listing Roles configuration',
            patientFound: 'Patient Found',
            errorBranch: 'Error when consulting programs X branch',
            msgPermissionsAuth:
                'Unable to display list of Authorization Programs by permissions, Contact Super Administrator',
            errorRequestPrograms: 'Error: Request Programs',
            errorUpdateRequestPrograms: 'Error API update Request Programs',
            errorPipelines: 'Error listing Business Pipelines',
            msgPermissionsPDF:
                'Unable to display Editor by permissions, Contact Super Administrator',
            errorSaveImg: 'Failed to save pdf or images, try again',
            msgPermissionsIntegrator:
                'Unable to display Integrator List due to permissions, Contact Super Administrator',
            msgPermissionsMedicine:
                'Unable to display List of Groupers by permissions, Contact Super Administrator',
            errorUpdateRol: 'Error updating a role',
            updateRol: 'Role updated correctly',
            errorCreateRol: 'Error creating a role',
            createRol: 'Role created correctly',
            deleteRolConfig: 'Delete role configuration',
            deleteRol: 'Delete role',
            msgSure: 'Are you sure you want to continue?',
            msgConfigRole: 'Role configuration deleted successfully',
            msgRole: 'Role deleted successfully',
            errorDeleteRolConfig: 'Error deleting a role configuration',
            errorDeleteRol: 'Error deleting a role',
            msgPermissionsTrm:
                'Unable to display TRM list by permissions, Contact Super Administrator',
            msgPermissionsUsers:
                'Unable to display user list by permissions, Contact Super Administrator',
            msgPermissionsPharmacy:
                'Unable to display list of Pharmacies by permissions, Contact Super Administrator',
            msgConfigRol: 'Role Configuration updated correctly',
            errorConfigRol: 'Error updating role configuration',
            emptyFields: 'Empty Fields',
            notSelectRol: 'You have not selected a role',
            notSelectCountry: 'You have not selected a country',
            notCreate: 'Cannot create Configuration',
            existConfiguration: 'A configuration exists for this role, country and branch',
            createConfigRol: 'Role configuration saved correctly',
            errorCreateConfigRol: 'Error saving Role Configuration',
            msgPermissionsTop:
                'Unable to display Top Cards list by permissions, Contact Super Administrator',
            msgPermissionsBranch:
                'Cannot display list of Branch and Matrices by permissions, Contact Super Administrator',
            msgAdminAccess: 'You must log in to the medical interface to have Admin access.',
            errorDiscounts: 'Error listing Discounts'
        },
        formMedical: {
            titulo: 'Onboarding Medical',
            altura: 'Your height is (cm):',
            peso: 'Your weight is (kg):',
            primerPeriodo: 'When was your first menstrual period?',
            diasPeriodo: 'How many days does your period usually last?',
            dolorPeriodo: 'How much pain do you feel during your period?',
            padecimientos: 'Do you have or have you had any of these conditions?',
            padecimientosFamiliares: 'Do you have a family history of any of these conditions?',
            padecimientosInfertilidad:
                'Do you have any of these conditions related to infertility?',
            estudiosInfertilidad: 'Have you had studies related to infertility?',
            embarazada: 'Have you been pregnant?',
            partos: 'How many births have you had?',
            procedimientos: 'Have you had any of these procedures?',
            alergias: 'Do you have any allergies?',
            medicamentos: 'Do you take any medication?',
            tratamientosprevios: 'Have you undergone assisted reproduction treatment before?',
            timpoIntentoBebe: 'How long have you been looking to have a baby?',
            tiempodispuestaesperar: 'How soon would you like to have your baby?',
            diabetes: 'Diabetes',
            cardiacos: 'Heart problems',
            hipertension: 'Hypertension',
            cancer: 'Cancer',
            vih: 'HIV',
            hepatitis: 'Hepatitis',
            otros: 'Others',
            ninguno: 'None',
            endometriosis: 'Endometriosis',
            miomatosis: 'Myomatosis',
            quistes: 'Cysts',
            agnesia_uterina: 'Uterine agenesis',
            ovario_poliquistico: 'Polycystic ovary syndrome',
            ultrasonido: 'Ultrasound',
            transferencia_embionaria: 'Embryo transfer test',
            hormonales: 'Homonal',
            seminograma: 'Seminogram',
            dialisis: 'Dialysis',
            transplantes: 'Transplants',
            legrado: 'Curettage',
            cesarea: 'Caesarean section'
        },
        Language: 'Language',
        Donante: 'Donor',
        Paciente: 'Patient',
        yes: 'yes',
        textInactivity: 'IM detected inactivity for a long period, please update',
        acceptText: 'OK'
    },
    //Spanish Language
    es: {
        login: {
            signIn: 'Iniciar Sesión',
            signInButton: 'Ingresar con Google',
            userPass: 'Usuario y Contraseña',
            logout: 'Cerrar Sesión',
            logoutButton: 'Salir',
            send: 'Enviar',
            back: 'Volver',
            username: 'Nombre de Usuario',
            password: 'Contraseña',
            enterUsername: 'Escriba su usuario',
            enterPassword: 'Escriba su contraseña',
            loggingin: 'Iniciando Sesion....'
        },
        admin: {
            title: 'Configuración Administrador',
            back: 'Regresar',
            pdfTitle: 'Editor PDF',
            users: {
                title: 'Usuarios',
                createUser: 'Crear Usuario',
                editUser: 'Editar Usuario',
                placeholderSearch: 'Buscar Usuario',
                newUser: 'Nuevo Usuario',
                fullName: 'Nombre Completo',
                status: 'Estado',
                cancel: 'Cancelar',
                create: ' Crear',
                next: 'Siguiente',
                save: 'Guardar',
                name: 'Nombre',
                email: 'Correo',
                role: 'Rol',
                userCreate: 'Usuario Creado Correctamente',
                errorCreateUser: 'Error al crear usuario',
                errorRole: 'Error al cargar roles',
                updateUser: 'Usuario actualizado con email',
                country: 'Pais',
                generic: 'Usuario Generico'
            },
            pharmacy: {
                title: 'Farmacia',
                cancel: 'Cancelar',
                next: 'Siguiente',
                branch: 'Sede',
                branches: 'Sedes',
                select: 'Selecciona una Opción',
                create: 'Crear Farmacia',
                Associated: 'Farmacias Asociadas',
                Disassociate: 'Desasociar Farmacia',
                Back: 'Regresar',
                search: 'Buscar sede',
                message: 'Está seguro que desea desasociar',
                message2: 'de Sede',
                save: 'Guardar',
                editTitle: 'Editar Farmacia Asociada',
                name: 'Nombre',
                code: 'Codigo',
                messageError:
                    'No se ha encontrado ninguna farmacia asociada al nombre de la sede enviada.',
                update: 'Farmacia Actualizada',
                updateError: 'Un error ha ocurrido mientra se actualizaba Farmacia',
                deleted: 'Farmacia Eliminada',
                deletedError: 'Un error ha ocurrido al borrar la farmacia',
                nameInput: 'Nombre de la farmacia',
                locality: 'Localidad',
                branchInput: 'Sede Origen',
                savePharmacy: 'exitosamente creado',
                errorCreatePharmacy: 'Farmacia no creada',
                errorBranch: 'Error al cargar Sucursales'
            },
            auth: {
                title: 'Autorización Programas',
                pending: 'Solicitudes Pendientes',
                expired: 'Solicitudes Vencidas',
                approved: 'Solicitudes Autorizadas',
                unauthorized: 'Solicitudes No Autorizadas',
                date: 'Fecha',
                profile: 'Perfil',
                score: 'Score',
                request: 'Programas a solicitar',
                authorized: 'Autorizado por',
                patient: 'Paciente',
                amount: 'Cantidad',
                justification: 'Justificación',
                disapproval: 'Justificación desaprobación',
                notApprove: 'No aprobar',
                approve: 'Aprobar',
                titleDisapproval: 'Desaprobación',
                messageDisapproval: 'Justifique aqui el motivo de desabrobación',
                placeholder: 'Escriba su justificación aqui...',
                notSelected: 'Seleccione un Programa',
                submit: 'Solicitud Enviada',
                noData: 'No hay solicitudes disponibles',
                name: 'Nombre',
                dataPatient: 'Datos Paciente',
                applicant: 'Solicitante',
                none: 'Ninguno',
                errAuthApprove: 'ERROR: Al consultar Autorizaciones Aprobadas',
                errAuthNotApproved: 'ERROR: Al consultar Autorizaciones Rechazadas',
                errAuthPending: 'ERROR: Al consultar Autorizaciones Pendientes'
            },
            authDiscounts: {
                title: 'Autorización Descuentos',
                pending: 'Descuentos Pendientes',
                expired: 'Descuentos Vencidos',
                approved: 'Descuentos Autorizados',
                unauthorized: 'Descuentos No Autorizados',
                show_detail: 'Mostrar Detalle',
                product: 'Producto',
                precio_lista: 'Precio de Lista',
                precio_descuento: 'Precio Descuento',
                porcentaje_descuento: 'Descuento',
                usr_aprueba: 'Usuario Aprueba',
                usr_solicita: 'Usuario Solicita',
                fecha_solicita: 'Fecha Solicitud',
                estado: 'Estatus',
                request: 'Descuentos a solicitar',
                change_unit: 'cambiar de unidad de medida',
                usr_desaprobo: 'usuario que desaprobó',
                data_pacient: 'Datos paciente',
                name_pacient: 'Nombre:',
                data_score: 'Datos del score',
                need_pacient: 'Necesidad',
                nhc: 'NHC',
                score: 'Score',
                score_mx: 'Score MX',
                date_first: 'Fecha de primera cita'
            },
            trm: {
                title: 'Configuración TRM',
                value: 'Valor de TRM',
                edit: 'Editar',
                save: 'Guardar',
                history: 'Historico TRM',
                responsible: 'Responsable',
                createAt: 'Creado',
                saving: 'Guardando',
                valueUpdate: 'Valor TRM Actualizado'
            },
            medicines: {
                title: 'Medicamentos',
                cycles: 'Ciclos',
                stimulation: 'Estimulación',
                dosage: 'Dosis',
                medicine: 'Medicamento',
                newOption: 'Nueva Opción',
                addedOptions: 'Opciones Agregadas',
                placeholder: 'Agregar nueva Opción',
                show: 'Mostrar Detalles',
                hide: 'Ocultar Detalles',
                options: 'Opciones',
                cancel: 'Cancelar',
                done: 'Guardar',
                name: 'Nombre',
                placeholderSearch: 'Buscar  Agrupador',
                edit: 'Editar Agrupador',
                create: 'Crear Agrupador',
                intelisis: 'Codigo Intelisis',
                validation: 'Cada codigo separado por una coma',
                success: 'Agrupador creado correctamente',
                loading: 'Guardando...',
                status: 'Estado',
                update: 'Agrupador Actualizado Correctamente',
                msgDuplicate: 'Codigo intelisis duplicado, por favor valide la información'
            },
            integrator: {
                title: 'Integrador',
                editIntegrator: 'Editar Integrator',
                createIntegrator: 'Crear Integrator',
                name: 'Nombre Integrador',
                company: 'Empresa',
                success: 'Exitoso',
                messageSuccess: 'Integrador creado correctamente',
                messageError: 'No se pudo crear integrador',
                messageSuccessEdit: 'Integrador correctamente Actualizado',
                messageErrorEdit: 'No se pudo editar integrador',
                delete: 'Estas seguro de eliminar Integrador ?',
                messageDelete: 'Integrador eliminado correctamente',
                messageDeleteError: 'No se pudo eliminar integrador'
            },
            top: {
                title: 'Top 10 Tarjetas',
                editCard: 'Editar Vista',
                createCard: 'Crear Vista',
                nameView: 'Nombre de la Vista',
                optionSede: 'Sede Origen',
                optionTop: 'Opciones Top 10',
                search: 'Buscar Producto',
                initialAppointment: 'Seleccione una Sede de Origen',
                category: 'Seleccione una Categoria',
                pharmacy: 'Seleccione una Farmacia',
                rol: 'Seleccione un Rol',
                selectCategory: 'Por favor seleccione una Categoria',
                branch: 'Sucursal',
                name: 'Nombre',
                errorBranch: 'Error API al consultar Sucursales',
                errorRol: 'Error API al consultar Roles',
                errorProducts: 'Error API al consultar Productos',
                errorCreatingRegeneraProducts: 'Error al crear productos regenera',
                notSelectInitialAppointment: 'No haz seleccionado Sede de Origen',
                notSelectCategory: 'No haz seleccionado una Categoria',
                notSelectRol: 'No haz seleccionado un Rol',
                repeatFiels: 'Hay campos repetidos, valide nuevamente',
                greater: 'Hay valores mayores a 10, valide nuevamente',
                less: 'Hay valores menores a 1, valida nuevamente',
                success: 'Exitoso',
                createView: 'Vista creado correctamente',
                errorCard: 'Error al crear vista',
                messageSuccessEdit: 'Información Actualizada',
                errorUpdateCard: 'Error al Actualizar',
                delete: 'Estas seguro de Eliminar ?',
                messageDelete: 'Eliminado',
                errorDelete: 'Error al eliminar',
                optionTopWarning: 'Debes seleccionar al menos una opcion top',
                existCard: 'La card a guardar ya existe.',
                errorPharmacy: 'Error al listar Farmacias',
                notSelectPharmacy: 'No haz seleccionado una Farmacia',
                allBranch: 'Todas las sucursales'
            },
            role: {
                title: 'Admin Roles',
                titleBusiness: 'Administracion de Negocios, Programas y Pdf',
                titleDiscounts: 'Administrador de descuentos',
                subtitle: 'Configuración de Roles',
                name: 'Nombre de Rol',
                createMode: 'Modo Crear',
                editMode: 'Modo Editar',
                addRole: 'Agregar un Rol +',
                msgRole: 'Si el nombre de Rol contiene la palabra admin sera un rol Administrador',
                edit: 'Editar',
                delete: 'Eliminar',
                auth: 'Autorizar',
                search: 'Buscar Rol',
                msgPermissions:
                    'No se puede mostrar lista de Roles por permisos, Contacte con el Super Administrador',
                createConf: 'Crear Configuración',
                clone: 'Clonar',
                msgPermissionList:
                    'No se puede mostrar Lista de configuracion de Roles por permisos, Contacte con el Super Administrador',
                actions: 'Acción',
                country: 'Pais',
                branch: 'Sucursal',
                formRole: 'Formulario de Configuración de Rol',
                role: 'Rol',
                selectRole: 'Seleccione un Rol',
                selectCountry: 'Seleccione un Pais',
                selectBranch: 'Seleccionar una Sucursal',
                administrator: 'Administrador',
                accessWindow: 'Acceso Ventana',
                programs: 'Programas',
                techniques: 'Tecnicas',
                labs: 'Laboratorios',
                surgeries: 'Cirugias',
                individualMedicina: 'Medicina Individual',
                packageMedicine: 'Medicina Paquete',
                history: 'Historial de propuestas',
                sspta: 'Solicitud de Propuesta (SSPTA)',
                medicines: 'Medicinas',
                cycles: 'Ciclos',
                recipe: 'Resumen de Solicitud de propuesta',
                pdf: 'Editor PDF',
                showPdf: 'Mostrar Editor PDF',
                imgPDF: 'Configuracón de Imagenes',
                save: 'Guardar PDF',
                create: 'Crear',
                list: 'Listar',
                userManagement: 'Administración de Usuario',
                pharmacy: 'Farmacia',
                authPrograms: 'Autorización Programas',
                authDiscounts: 'Autorización Descuentos',
                accept: 'Aceptar Solicitudes',
                reject: 'Rechazar Solicitudes',
                adminMed: 'Administración de Medicinas',
                listGrouper: 'Listar Agrupador',
                createFilter: 'Crear Filtro',
                createGrouper: 'Crear Agrupador',
                editGrouper: 'Editar Agrupador',
                change: 'Cambiar Estado Agrupador',
                integrator: 'Integrador',
                listRole: 'Listar Rol',
                createRole: 'Crear Rol',
                editRole: 'Editar Rol',
                deleteRole: 'Eliminar Rol',
                listConf: 'Listar Configuración de Rol',
                createConfRole: 'Crear Configuración de Rol',
                editConf: 'Editar Configuración de Rol',
                cloneConf: 'Clonar Configuración de Rol',
                deleteConf: 'Eliminar Configuración de Rol'
            },
            bussiness: {
                title: 'Admin Negocios',
                admin: 'Administracion de Sucursales',
                subtitle: 'Administrador negocios y categorías',
                search: 'Buscar Negocio',
                searchPrograms: 'Buscar Programas',
                msgUnable:
                    'No se puede mostrar Lista de configuracion de Sucursales y Negocios por permisos, Contacte con el Super Administrador',
                formBussiness: 'Formulario Configuracion de Negocios y Categorias',
                formPrograms: 'Formulario Configuración de Programas y PDF',
                showCategory: 'Categorias de productos a mostrar',
                selectCategory: 'Seleccione una Categoria',
                Category: 'Categorias',
                nameCard: 'Nombre de Label Card',
                unique: 'Unico',
                add: 'Añadir',
                searchProduct: 'Buscar Producto',
                product: 'Producto',
                pipelineCreate: 'Pipeline donde creara Negocio',
                pipelineStage: 'Etapa donde llegara el Negocio',
                categoryAssociate: 'Categoria asociada al Negocio',
                conditionProduct: 'Restricciones de generación de propuesta a pacientes',
                conditionPrograms: 'Condiciones para Programas',
                showPrograms: 'Programas a visualizar',
                searchProperty: 'Buscar Propiedad',
                optionHubspot: 'Opción  Hubspot',
                pdfCondicion: 'Condiciones Color de Pdf',
                nameHS: 'Nombre Interno General en HS',
                nameOptionHS: 'Nombre Interno de la Opción en HS',
                addProperty: 'Agregar Propiedad Adicional',
                showPDF: 'Entonces muestra el PDF',
                optional: 'Opcional',
                colorShow: 'Color a mostrar en Pdf',
                optionalColor: 'Color Opcional a mostrar en pdf',
                name: 'Nombre',
                code: 'Codigo',
                bitregenera: 'Regenera',
                estatusregeneraActivo: 'Activo',
                estatusregeneraInactivo: 'Inactivo',
                createdRegeneraProduct: 'Producto Regenera creado con exito',
                bitregeneraUpdate: 'Configuracion Bit Regenera actualizado con exito',
                estatus: 'Estatus',
                agregarproductoregenera: 'Agregar Producto Regenera',
                category: 'Categoria',
                typeCard: 'Tipo Card',
                actions: 'Acciones',
                options: 'Opciones',
                view: 'Visualizar',
                msgDeleteConfirm: 'Esta seguro de eliminar Configuración Sucursal y Negocio ?',
                msgConfigRemoved: 'Configuración Eliminado correctamente',
                errorDelete: 'Error al eliminar Negocio Pipelines',
                errorListPipelines: 'Error when listing Pipelines (Contact Properties)',
                errorListPipelinesTwo: 'Error when listing Pipelines',
                bussinessUpdate: 'Negocio Actualizado Correctamente',
                errorUpdateBussiness: 'Error al actualizar Negocio Pipelines',
                bussinessCreate: 'Negocio creado correctamente',
                errorCreateBussiness: 'Error al crear Negocios',
                duplicatePipeline: 'Pipeline Duplicado, ya existe una configuración',
                confirmDelete: 'Esta seguro de eliminar categoria ?',
                msgDeletePipeline: 'Eliminara los pipelines asociados a esta categoria',
                selectOpcion: 'Seleccione una Opción',
                deleteCondition: 'Esta seguro de eliminar condición ?',
                deleteConditionpPdf: 'Esta seguro de eliminar condición de pdf ?',
                deleteConditionProgram:
                    'Esta seguro de eliminar condición de configuracion de programas ?',
                programsAdmin: 'Admin Programas & PDF',
                productRegeneraAdmin: 'Productos Regenera',
                agregarproductRegeneraAdmin: 'Agregar productos a Regenera',
                msgSelectCountry: 'Debes seleccionar un pais para ver los productos',
                clear: 'Limpiar',
                programsCreate: 'Configuración de programas creado correctamente',
                errorCreateProgram: 'Error al crear configuración del programa',
                duplicateProgram: 'Configuracion de programa duplicado, validar la información',
                programsUpdate: 'Configuración de programas actualizado correctamente',
                errorUpdateProgram: 'Error al actualizar configuración de programa',
                msgDeleteProgram: 'Estas seguro de eliminar la configuración de programa?',
                configHubspot: 'Configuración propiedades hubspot',
                nameConfiguration: 'Nombre de configuración',
                PropertyValue: 'Valor de la Propiedad',
                notBecYsah: 'PROGRAMA EN PROGRESO',
                bundle: 'Paquetes',
                btnBundle: 'Armar Paquetes'
            },
            discounts: {
                title: 'Descuentos',
                search: 'Buscar descuento',
                name: 'Nombre',
                country: 'País',
                discount: 'Descuento',
                discountUpdate: 'Configuración del descuento configurado correctamente',
                errorDiscountUpdate: 'Error al actualizar la configuración del descuento',
                updateDiscounts: 'Se han actualizado {number} registros',
                updateDiscountsConfirm: 'Se van a actualizar {number} registros',
                formDiscount: 'Formulario de configuración de descuento',
                errorListContactProps: 'Error al listar las propiedades de contacto',
                configHubspot: 'Configuración propiedades hubspot',
                addProperty: 'Agregar Propiedad Adicional',
                clear: 'Limpiar',
                category: 'Categoria',
                selectCategory: 'Seleccione una Categoria',
                product: 'Producto',
                selectProduct: 'Seleccione un Producto',
                allProducts: 'Todos los Productos',
                nameConfiguration: 'Nombre de configuración',
                vigencyDate: 'Fecha de vigencia',
                save: 'Guardar',
                saveVigency: 'Guardar vigencia',
                selectAll: 'Seleccionar todo',
                unselectAll: 'Deseleccionar todo',
                saveDate: 'Fecha Guardada Correctamente',
                errorSaveDate: 'Error al guardar fecha',
                saveDiscounts: 'Guardar Descuentos'
            },
            branchMatriz: {
                title: 'Configuración de Islas & Matrices',
                notPredefined: 'Isla y Matriz no predefinida',
                predefined: 'Configuración Isla - Matriz Predefinida',
                branch: 'Isla',
                matrix: 'Matriz',
                selectBranch: 'Seleccione una Isla',
                selectMatrix: 'Seleccione una Matriz',
                category: 'Categoria',
                selectCategory: 'Seleccione una Categoria',
                product: 'Producto',
                selectProduct: 'Seleccione un Producto',
                exceptions: 'Excepciones',
                distribution: 'Repartición',
                actions: 'Acciones',
                deleteConfiguration: 'Eliminar Configuration',
                questionContinue: 'Desea Continuar?',
                associatedMatrix: 'Matriz asociada Inexistente',
                allProducts: 'Todos los Productos',
                success: 'Exitoso',
                msgSuccess: 'Configuración creado correctamente',
                msgUpdate: 'Configuración actualizado correctamente',
                msgDelete: 'Configuración eliminado correctamente'
            },
            bundles: {
                addphase: 'Añadir fase',
                name: 'Nombre',
                phase: 'Fase',
                optional: 'Opcional',
                alert: 'No se puede agregar. Producto ya existe dentro de la fase',
                branches: 'Sucursales',
                percentages: 'Porcentajes %',
                select: '-- Por favor seleccione un producto --',
                singleBusiness: 'Es un solo negocio?',
                chooseOrigins: 'Elige Origenes',
                chooseDestinations: 'Elige Destinos',
                addProduct: 'Añadir Producto',
                bundleCreated: 'Paquete creado exitosamente!',
                loading: 'Cargando...',
                details: 'Detalles',
                edit: 'Editar',
                enable: 'Activar',
                disable: 'Inactivar',
                required: 'Todos los campos del paquete son obligatorios',
                cancel: 'Cancelar',
                save: 'Guardar',
                createBundle: 'Creando paquete...',
                origin: 'Origen',
                destination: 'Destino',
                single: 'Negocio único',
                productName: 'Nombre Producto',
                price: 'Precio',
                actions: 'Acciones',
                hsCreationMessage: 'Negocios creados exitosamente!',
                noBundle: 'No hay paquetes subrogado para el país seleccionado.'
            }
        },
        welcome: {
            welcome: 'Bienvenido',
            please: 'busque a su paciente',
            country: 'Elija el pais',
            email: 'Ingrese el correo electrónico del paciente',
            nhc: 'Ingrese el NHC',
            search: 'Buscar',
            usa: 'EEUU',
            error: {
                contact: 'El contacto',
                owner_id: 'no cuenta con owner_id',
                doctor_pvr: 'El contacto ${correo_contacto} no cuenta con un doctor pvr us',
                age: 'El contacto ${correo_contacto} no tiene edad',
                name: 'El contacto ${correo_contacto} no cuenta con nombre o apellido',
                pvr_status:
                    'El contacto ${correo_contacto} no cuenta con estado de pvr o confirmacion checkout pvr',
                fertility_treatments:
                    'El contacto ${correo_contacto} no cuenta valor en la propiedad how_much_do_you_know_about_fertility_treatments',
                about_yourself:
                    'El contacto ${correo_contacto} puede que no cuente con valor en alguna de las propiedades: how_much_do_you_know_about_fertility_treatments_ ó tell_us_more_about_yourself'
            }
        },
        history: {
            Title: 'Historial de propuestas',
            femenino: 'Femenino',
            masculino: 'Masculino',
            Gender: 'Sexo',
            Patient: 'Paciente',
            Proposal: 'Nueva Propuesta',
            table: {
                Quote: 'N° de Cotizacion',
                Date: 'Fecha',
                Price: 'Valor',
                Status: 'Estado',
                Details: 'Detalle',
                entregada: 'Entregada',
                See: 'Ver',
                detailBusiness: {
                    Quote: 'Numero de Cotización',
                    Detail: 'Detalle',
                    Price: 'Precio',
                    Created: 'Creado',
                    Business: 'Negocios',
                    Category: 'Categoria',
                    BusinessCreated: 'Negocio Creado',
                    Country: 'Pais',
                    Products: 'Productos',
                    Articles: 'Articulos',
                    Article: 'Articulo',
                    Code: 'Codigo'
                }
            },
            pdf: {
                view: 'Ver Historial PDFs',
                recent: "PDF's Recientes",
                button: 'Ver Historial PDFs'
            }
        },
        menu: {
            Admin: 'Administrador',
            Form: 'Cotizaciones',
            History: 'Historial de propuestas',
            Proposal: 'SSPTA',
            Medicine: 'Medicamentos',
            Cycles: 'Ciclos',
            Key_Differentiators: 'Diferenciadores',
            Recipe: 'Resumen de Solicitud de propuesta',
            Logout: 'Cerrar Sesion',
            onboarding: 'Onboarding Médico'
        },
        form: {
            Title: 'Cotizaciones - Propuesta de servicio',
            Country: 'Selecciona el Pais',
            Program: 'Selecciona el Programa',
            Images: 'Imagenes',
            TableProgram: 'Tabla(Programa)',
            TableCoverage: 'Tabla(Seguros)',
            Position: 'Posicion',
            FileName: 'Nombre Archivo',
            View: 'Visualización',
            Actions: 'Acciones',
            Save: 'Guardar',
            Delete: 'Borrar',
            Update: 'Actualizar',
            TypeA: 'Road Tipo A',
            TypeV: 'Road Verde',
            TypeB: 'Road Azul',
            variables: 'Variables Editor',
            Language: 'Escoga el idioma',
            size: 'Imagen con tamaño maximo de 650px',
            uploadImage: 'Subir Imagen',
            view: 'Ver',
            Yellow: 'Amarillo',
            Intermediate: 'Intermedio',
            IntermediateHight: 'Intermedio-Alto',
            IntermediateLow: 'Intermedio-Bajo',
            White: 'Blanco',
            Score: 'Selecciona el score',
            Variables: {
                nhc: 'NHC del paciente',
                timestamp: 'Fecha actual',
                patientName: 'Nombre del Paciente',
                programName: 'Nombre del Programa',
                docInfo: 'Nombre del Doctor',
                medAssistance: 'Nombre del Asistente Medico',
                tableComponents: 'Tabla de Componentes',
                tableMedicine: 'Tabla de Medicinas',
                addressUS: 'Dirección Sucursal USA',
                addressMX: 'Dirección Sucursal Mexico',
                sede: 'Sede seleccionado',
                breakPage: 'Salto de Pagina'
            },
            Alert: {
                none: 'No haz seleccionado ninguna imagen',
                repeat: 'Posicion repetida en Imagenes(Road)',
                repeatText: 'Por favor valida la posicion de cada imagen',
                saveTitle: 'Quieres guardar el texto y/o imagenes?',
                saveText: 'Reemplazara las imagenes y/o texto actuales',
                save: 'Guardar',
                noSave: 'No Guardar',
                cancel: 'Cambios no guardados',
                okTitle: 'Guardando',
                okText: 'Guardando pdf e imagenes',
                error: 'A ocurrido un error al subir las imagenes'
            }
        },
        proposal: {
            Title: 'Solicitud de Propuesta(SSPTA)',
            Button: 'No requiere propuesta',
            Programs: 'Programas',
            ProgramsUsa: 'PROGRAMAS TU ESTRELLA EN CASA',
            Protection: 'Protección',
            Techniques: 'TECNICAS',
            Coverage: 'Seguro',
            Laboratory: 'LABORATORIO',
            Go: 'Ir a Resumen de Solicitud de Propuesta',
            Tecnica: 'Tecnica',
            noSelected: 'No has seleccionado una Farmacia',
            noFound: 'Deber seleccionar una farmacia o farmacia no tiene medicamentos',
            errorIndividual: 'No hay resultados para Medicina Individual',
            Surgery: 'Cirugias',
            Eligender: 'Eligender',
            noRecords: 'No hay registros',
            Price: 'Precio',
            Article: 'Artículo',
            noProposal: {
                title: 'Razones de no requiere propuesta',
                placeholder: 'Escriba algo... Minimo 5 caracteres',
                buttonConfirm: 'Confirmar',
                buttonCancel: 'Cancelar'
            },
            AdminPermisions: {
                buttonShow: 'Mostrar todos los programas',
                title: 'Permiso de Administrador',
                text: 'Elija los programas que desea que se le autorizen',
                justify: 'Justifique aquí su solicitud',
                justifyPlaceholder: 'Escriba su justificación aqui',
                buttonSend: 'Enviar Solicitud',
                buttonCancel: 'Cancelar',
                wait: 'Espere...',
                process:
                    'Tramitando su solicitud, puede cerrar este popup la solicitud se realiza en segundo plano',
                messageSucess: 'Solicitud Aprobada',
                approvedPrograms: 'Programas Aprobados',
                authorized: 'Autorizado por',
                messageError: 'Solicitud No Aprobada',
                justification: 'Justificación Desaprobación',
                unauthorized: 'No Autorizado por',
                timeExceeded: 'Tiempo excedido para esta solicitud',
                requestCancelled: 'Solicitud Cancelada',
                statusRequest: 'Estado Solicitud',
                detailsRequest: 'Detalles Ultima Solicitud',
                ProgramsRequest: 'Programas Solicitados',
                AuthorizationStatus: 'Estado Autorización',
                DateRequest: 'Fecha de Solicitud',
                Applicant: 'Solicitante',
                reason: 'Motivo no Autoriza',
                authorizer: 'Autorizador',
                close: 'Cerrar'
            },
            Medicine: {
                titlePackage: 'MEDICAMENTO PAQUETE',
                titleIndividual: 'MEDICAMENTO INDIVIDUAL',
                Pharmacies: 'FARMACIAS',
                search: 'Buscar Medicina',
                searchIndividual: 'Buscar Medicina Individual',
                Amount: 'Cantidad',
                Medicine: 'Medicamento',
                Price: 'Precio',
                Cash: 'Precio Contado',
                Cash2: 'Precio Efectivo',
                Credit: 'Precio Credito',
                MSI: 'Precio MSI'
            }
        },
        medicine: {
            title: 'Medicamentos',
            medicationManagement: 'Control de Medicamentos',
            Fertility: 'Fertilidad',
            textMedicineUS: 'El paciente no compra sus medicamentos en Ingenes',
            date: 'Fecha de Prescripción',
            titlePackage: 'Medicamento Paquete',
            titleIndividual: 'Medicamento Individual',
            Medicine: 'Medicamento',
            Include: 'Incluye',
            Delivered: 'Entregado',
            Remaining: 'Restante',
            Detail: 'Detalle',
            cycle: 'Tipo Ciclo',
            select: 'Seleccionar',
            stimulation: 'Tipo Estimulación',
            dosage: 'Tipo Dosis',
            medicine: 'Tipo de Medicinas',
            msgStimulation: 'No haz seleccionado un ciclo',
            msgDosage: 'No haz seleccionado un Ciclo',
            msgMedicine: 'No haz seleccionado una Dosis',
            apply: 'Aplicar',
            delete: 'Borrar',
            warning: 'Advertencia',
            incomplete: 'Campos Incompletos',
            confirmButton: 'Confirmar Medicamentos',
            textAlert: `<div class="text-left">
          <p>La cantidad de medicina excede al numero de medicamentos restantes,
          Por favor seleccione alguna de las siguientes opciones. </p><br>
          <p> <b>1) Corregir:</b> Se hace un ajuste de la cantidad al valor de los medicamentos restantes.  </p>
          <p> <b>2) Crear negocio:</b> se genera la autorización por las cantidades restantes y se abre la página de receta para confirmar la creación de un nuevo negocio por las cantidades adicionales o superiores a las restantes”.</p>
        </div>`,
            correct: 'Corregir',
            CreateBusiness: 'Crear Negocio',
            continue: 'Desea continuar ?',
            table: {
                medicine: 'Medicamento',
                includes: 'incluye',
                delivered: 'Entregado',
                remaining: 'Restante',
                select: 'Seleccionar',
                amount: 'Cantidad'
            },
            success: 'Exitoso',
            msgSuccess: 'Negocio Creado correctamente',
            selectMedicine: 'No ha seleccionado ninguna Medicina',
            correctMedicine: 'No se puede corregir porque no hay medicinas restantes',
            msg1:
                'Negocio Creado correctamente, en esta vista se muestra las medicinas excedente con el precio especial',
            msg2: 'En esta vista se muestra las medicinas excedente con el precio especial'
        },
        navbarInfo: {
            Quotation: 'Cotización',
            USA: 'EEUU',
            sedeOrigen: 'Sede Origen',
            sedeDestino: 'Sede Destino',
            patientName: 'Nombre',
            labelsubtipo: 'Subtipo',
            labelperfil: 'Perfil',
            labeltipo: 'Tipo'
        },
        recipe: {
            Title: 'Solicitud de Propuesta(SSPTA)',
            smallTitle: 'Resumen de Solicitud de propuesta',
            Button: 'Nueva Propuesta',
            titleMain: 'Resumen del Negocio',
            components: 'Componentes Adicionales',
            componentsMac: 'Solicitudes Mac',
            loadingPdf: 'Cargando PDF',
            loadingPdfMed: 'Cargando PDF Medicina',
            loadingPdfMac: 'Cargando PDF Mac',
            waitMoment: 'Espere un momento',
            Table: {
                Unit: 'Unidad',
                Description: 'Descripción',
                Quantity: 'Cantidad',
                Price: 'Precio Unitario',
                PriceTotal: 'Precio Total'
            },
            Alert: {
                noCreate:
                    'No se puede crear una empresa porque el contacto no tiene un ID de propietario(Owner ID)',
                createTitle: '¿Está seguro de que quiere crear estos negocios?',
                createText: '¡No podrá revertir esto!',
                save: 'Guardar',
                successTitle: 'Confirmación',
                successText: 'Tus negocios han sido creados con éxito en HubSpot',
                errorText: 'Tus negocios no han sido creados en HubSpot',
                cancelTitle: 'Cancelado',
                cancelText: 'Proceso Cancelado!',
                Warning: 'Advertencia',
                processBussiness: 'Paciente cuenta con negocio(s) en proceso.',
                businessControl: 'Error Api Control Negocio',
                stage: 'Etapa',
                bussinessId: 'Negocio ID',
                pipeline: 'Nombre Pipeline',
                bussiness: 'Negocio',
                onlyCreate: 'Solo esta permitido crear',
                continue: 'Deseas Continuar?',
                notAllowed: 'No esta permitido crear Tecnicas ni YSAH',
                noBec:
                    'Lo sentimos no se puede crear otro programa BEC, ya existe un BEC activo. Los otros elementos solicitados sí se crearán',
                noYASH:
                    'Lo sentimos no se puede crear otro programa, ya existe un YASH activo. Los otros elementos solicitados sí se crearán',
                errorCreateProducts: 'Error al generar JSON data de productos',
                msgLoading: 'Por favor no cierre la pagina hasta que termine el proceso'
            },
            Confirm: 'Confirmar',
            PDF: 'Ver PDF',
            Loading: 'Cargando....',
            PdfMedicine: 'Ver PDF Medicamentos'
        },
        permissions: {
            unauthorized: 'No estas autorizado para ingresar a',
            validateAdmin: 'Valide con el Administrador',
            configAdmin: 'Configuración de Administrador',
            unauthorizedRol: 'El usuario no tiene configurado un rol o no tiene permisos',
            serverError: 'Error de Servidor, intento nuevamente',
            errorCard: 'Error al obtener Card Pais',
            noAssociatedScore: 'El paciente no tiene asociado un score para imprimir el pdf',
            notSelectProduct: 'No tiene seleccionado ningun producto',
            notSelectBranch: 'No tiene seleccionado Sede',
            errorProducts: 'Error al consultar Productos',
            errorMedicine: 'Error al crear cotizacion medicamento',
            errorQuote: 'Error al crear cotización',
            errorPdf: 'Error al subir el pdf',
            errorRole: 'Error al listar Roles',
            errorConfigRole: 'Error al listar configuracion Roles',
            patientFound: 'Paciente Encontrado',
            errorBranch: 'Error al consultar programas X sucursal',
            msgPermissionsAuth:
                'No se puede mostrar lista de Autorización Programas por permisos, Contacte con el Super Administrador',
            errorRequestPrograms: 'Error: Solicitudes Programas',
            errorUpdateRequestPrograms: 'Error API actualizar Solicitud Programas',
            errorPipelines: 'Error al listar Negocio Pipelines',
            msgPermissionsPDF:
                'No se puede mostrar Editor por permisos, Contacte con el Super Administrador',
            errorSaveImg: 'Error al guardar pdf o imagenes, intentelo nuevamente',
            msgPermissionsIntegrator:
                'No se puede mostrar Lista de Integradores por permisos, Contacte con el Super Administrador',
            msgPermissionsMedicine:
                'No se puede mostrar Lista de Agrupadores por permisos, Contacte con el Super Administrador',
            errorUpdateRol: 'Error updating a role',
            updateRol: 'Rol Actualizado Correctamente',
            errorCreateRol: 'Error al crear un rol',
            createRol: 'Rol creado correctamente',
            deleteRolConfig: 'Eliminar Configuración de Rol',
            deleteRol: 'Eliminar Rol',
            msgSure: 'Estas seguro de continuar?',
            msgConfigRole: 'Configuración de Rol, configurado correctamente',
            msgRole: 'Rol eliminado exitosamente',
            errorDeleteRolConfig: 'Error al eliminar una configuración de rol',
            errorDeleteRol: 'Error eliminando un rol',
            msgPermissionsTrm:
                'No se puede mostrar lista de TRM por permisos, Contacte con el Super Administrador',
            msgPermissionsUsers:
                'No se puede mostrar lista de usuario por permisos, Contacte con el Super Administrador',
            msgPermissionsPharmacy:
                'No se puede mostrar lista de Farmacias por permisos, Contacte con el Super Administrador',
            msgConfigRol: 'Configuración de Rol actualizado Correctamente',
            errorConfigRol: 'Error al actualizar la configuración de Rol',
            emptyFields: 'Campos Vacios',
            notSelectRol: 'No haz seleccionado un Rol',
            notSelectCountry: 'No haz seleccionado un Pais',
            notCreate: 'No se puede crear Configuración',
            existConfiguration: 'Existe una configuración para este rol, pais and sucursal',
            createConfigRol: 'Configuración de Rol guardado Correctamente',
            errorCreateConfigRol: 'Error al guardar Configuración de Rol',
            msgPermissionsTop:
                'No se puede mostrar listado de Top Cards por permisos, Contacte con el Super Administrador',
            msgPermissionsBranch:
                'No se puede mostrar listado de Islas y Matrices por permisos, Contacte con el Super Administrador',
            msgAdminAccess: 'Debes acceder a la interfaz medica para tener los acceso Admin.',
            errorDiscounts: 'Error al listar el descuentos'
        },
        formMedical: {
            titulo: 'Onboarding Médico',
            altura: 'Tu altura es (cm):',
            peso: 'Tu peso es (kg):',
            primerPeriodo: '¿Cuándo fue tu primer período menstrual?',
            diasPeriodo: '¿Cuántos días suele durar tu periodo?',
            dolorPeriodo: '¿Cuánto dolor sientes durante tu período?',
            padecimientos: '¿Tienes o has tenido algunos de estos padecimientos?',
            padecimientosFamiliares:
                '¿Tienes antecedentes familiares de alguno de estos padecimientos?',
            padecimientosInfertilidad:
                '¿Tienes alguno de estos padecimientos relacionados con infertilidad?',
            estudiosInfertilidad: '¿Te has realizado estudios relacionados con infertilidad?',
            embarazada: '¿Has estado embarazada?',
            partos: '¿Cuántos partos has tenido?',
            procedimientos: '¿Te han realizado alguno de estos procedimientos?',
            alergias: '¿Tienes alguna alergia?',
            medicamentos: '¿Tomas algún medicamento?',
            tratamientosprevios: '¿Ya has realizado un tratamiento de reproducción asistida?',
            timpoIntentoBebe: '¿Hace cuánto estás buscando tener un bebé?',
            tiempodispuestaesperar: '¿En cuánto tiempo te gustaría tener a tu bebé? ',
            diabetes: 'Diabetes',
            cardiacos: 'Problemas cardiacos',
            hipertension: 'Hipertensión',
            cancer: 'Cáncer',
            vih: 'VIH',
            hepatitis: 'Hepatitis',
            otros: 'Otro',
            ninguno: 'Ninguno',
            endometriosis: 'Endometriosis',
            miomatosis: 'Miomatosis',
            quistes: 'Quistes',
            agnesia_uterina: 'Agenesia uterina',
            ovario_poliquistico: 'Síndrome de Ovario Poliquístico',
            ultrasonido: 'Ultrasonido',
            transferencia_embionaria: 'Prueba de transferencia embrionaria',
            hormonales: 'Hormonales',
            seminograma: 'Seminograma',
            dialisis: 'Diálisis',
            transplantes: 'Transplantes',
            legrado: 'Legrado',
            cesarea: 'Cesárea'
        },
        Language: 'Idioma',
        Donante: 'Donante',
        Paciente: 'Paciente',
        yes: 'si',
        textInactivity: 'IM detecto inactividad por un periodo prolongado, favor de actualizar',
        acceptText: 'Aceptar'
    }
}
