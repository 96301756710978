import Vue from 'vue';
import Vuex from 'vuex';
import router from '../router';
import jwtdecode from 'jwt-decode';
import { initialForm } from '../components/Admin/Rol/initialDataFormRol';
import { commonPetition } from './commonPetition';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    imVersion: "v3.2.1",
    rol: process.env.VUE_APP_ROL || null,
    idRol: process.env.VUE_APP_IDROL || '',
    userResponse:
      process.env.VUE_APP_LOGIN === 'true'
        ? {
            email: process.env.VUE_APP_EMAIL || null,
            name: process.env.VUE_APP_NAME || null,
            picture: process.env.VUE_APP_IMG || null,
          }
        : null,
    count_pending: 0,
    sede_origen: {},
    pharmacy_selected: { label: '', value: null },
    filter_selected: {},
    configRol: initialForm,
    allConfigByRol: null,
    allConfigBussiness: null,
    configPDF: null,
    optionsBranch: {
      mxBranch: [],
      usBranch: [],
      destinoUS: [],
      destinoMX: [],
    },
    productsXCountry: null,
    configPrograms: null,
    typeUser: null,
    username: null,
    inactivity: false,
  },
  mutations: {
    setRol(state, rol) {
      state.rol = rol;
    },
    setIdRol(state, idRol) {
      state.idRol = idRol;
    },
    setUserResponse(state, user) {
      state.userResponse = user;
    },
    setCount(state, count) {
      state.count_pending = count;
    },
    setSedeOrigen(state, objSede) {
      state.sede_origen = objSede;
    },
    setPharmacy(state, objPharmacy) {
      state.pharmacy_selected = objPharmacy;
    },
    setFilter(state, objFilter) {
      state.filter_selected = objFilter;
    },
    setConfigRol(state, config) {
      state.configRol = config;
    },
    setAllConfigByRol(state, allConfig) {
      state.allConfigByRol = allConfig;
    },
    setAllConfigBussiness(state, allConfig) {
      state.allConfigBussiness = allConfig;
    },
    setConfigPDF(state, config) {
      state.configPDF = config;
    },
    setBranch(state, obj) {
      state.optionsBranch = obj;
    },
    setProductsXCountry(state, obj) {
      state.productsXCountry = obj;
    },
    setConfigPrograms(state, config) {
      state.configPrograms = config;
    },
    setTypeUser(state, config) {
      state.typeUser = config;
    },

    setUserName(state, config) {
      state.username = config;
    },
    setInactivity(state, value) {
      state.inactivity = value;
    },
  },
  actions: {
    guardarRol({ commit }, token) {
      commit('setRol', jwtdecode(token));
      localStorage.setItem('admin', token);
    },
    guardarIdRol({ commit }, id) {
      commit('setIdRol', id);
      localStorage.setItem('adminId', id);
    },
    guardarUserGoogle({ commit }, tokenId) {
      commit('setUserResponse', jwtdecode(tokenId));
      localStorage.setItem('token', tokenId);
    },
    autoLogin({ commit }) {
      const admin = localStorage.getItem('admin');
      const obj = localStorage.getItem('token');
      const idRol = localStorage.getItem('adminId');
      if (admin && obj && idRol) {
        commit('setRol', jwtdecode(admin));
        commit('setUserResponse', jwtdecode(obj));
        commit('setIdRol', idRol);
        router.push({ name: 'Welcome' });
      }
    },

    logout({ commit }, msg = '') {
      localStorage.clear();
      sessionStorage.clear();
      commit('setRol', null);
      commit('setUserResponse', null);
      commit('setUserName', null);
      router.push({ name: 'Home' });
      let time = msg === 'version' ? 4000 : 1000;
      setTimeout(() => {
        location.reload();
      }, time);
    },
    count_pendings({ commit }, count) {
      commit('setCount', count);
    },
    sedeOrigen({ commit }, objSede) {
      commit('setSedeOrigen', objSede);
    },
    pharmacySelected({ commit }, objPharmacy) {
      commit('setPharmacy', objPharmacy);
    },
    filtersSelected({ commit }, objFilter) {
      commit('setFilter', objFilter);
    },
    saveConfigRol({ commit }, config) {
      commit('setConfigRol', config);
    },
    allConfigByRol({ commit }, allConfig) {
      commit('setAllConfigByRol', allConfig);
    },
    allConfigBussiness({ commit }, allConfig) {
      commit('setAllConfigBussiness', allConfig);
    },
    configPDF({ commit }, config) {
      commit('setConfigPDF', config);
    },
    configPrograms({ commit }, config) {
      commit('setConfigPrograms', config);
    },
    typeUser({ commit }, type) {
      commit('setTypeUser', type);
    },
    userName({ commit }, username) {
      commit('setUserName', username);
    },
    validate_activity({ commit }, value) {
      commit('setInactivity', value);
    },
    ...commonPetition,
  },
});

export default store;
